import { default as _91_46_46_46404_93EQT9YOe45IMeta } from "/myedvenn/pages/[...404].vue?macro=true";
import { default as accountcgNCD5hmVzMeta } from "/myedvenn/pages/account.vue?macro=true";
import { default as contactMHEjAiwa3hMeta } from "/myedvenn/pages/admin/admin/contact.vue?macro=true";
import { default as indexRSFixR0mo5Meta } from "/myedvenn/pages/admin/admin/index.vue?macro=true";
import { default as reportFHnuPIG1K6Meta } from "/myedvenn/pages/admin/admin/report.vue?macro=true";
import { default as attendance_45_91status_93nDPSdVpFEaMeta } from "/myedvenn/pages/admin/administrative/attendance-[status].vue?macro=true";
import { default as indexLPSL0LDxk9Meta } from "/myedvenn/pages/admin/administrative/index.vue?macro=true";
import { default as index73KrNXB8z5Meta } from "/myedvenn/pages/admin/index.vue?macro=true";
import { default as datarfIHIoPQXmMeta } from "/myedvenn/pages/admin/market/data.vue?macro=true";
import { default as indexzvgibSL00FMeta } from "/myedvenn/pages/admin/market/index.vue?macro=true";
import { default as leadpeIpgb2xB0Meta } from "/myedvenn/pages/admin/market/lead.vue?macro=true";
import { default as _91attendanceId_93DHXaBa68wDMeta } from "/myedvenn/pages/doc/attendance/[attendanceId].vue?macro=true";
import { default as indexTFZplVLTX2Meta } from "/myedvenn/pages/index.vue?macro=true";
import { default as login5dXPFO301tMeta } from "/myedvenn/pages/login.vue?macro=true";
import { default as password_resetBM8o8ZmaLxMeta } from "/myedvenn/pages/password_reset.vue?macro=true";
import { default as attendance7Ius3w9yMjMeta } from "/myedvenn/pages/training/[id]/attendance.vue?macro=true";
import { default as calendarcLOju4kISdMeta } from "/myedvenn/pages/training/[id]/calendar.vue?macro=true";
import { default as contractac12tpG7WRMeta } from "/myedvenn/pages/training/[id]/contract.vue?macro=true";
import { default as indexQSMAK5gz06Meta } from "/myedvenn/pages/training/[id]/index.vue?macro=true";
import { default as _91id_93mhhjjPFOSuMeta } from "/myedvenn/pages/training/[id].vue?macro=true";
import { default as welcome4xGeziDxZzMeta } from "/myedvenn/pages/welcome.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_93EQT9YOe45IMeta?.name ?? "404",
    path: _91_46_46_46404_93EQT9YOe45IMeta?.path ?? "/:404(.*)*",
    meta: _91_46_46_46404_93EQT9YOe45IMeta || {},
    alias: _91_46_46_46404_93EQT9YOe45IMeta?.alias || [],
    redirect: _91_46_46_46404_93EQT9YOe45IMeta?.redirect,
    component: () => import("/myedvenn/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: accountcgNCD5hmVzMeta?.name ?? "account",
    path: accountcgNCD5hmVzMeta?.path ?? "/account",
    meta: accountcgNCD5hmVzMeta || {},
    alias: accountcgNCD5hmVzMeta?.alias || [],
    redirect: accountcgNCD5hmVzMeta?.redirect,
    component: () => import("/myedvenn/pages/account.vue").then(m => m.default || m)
  },
  {
    name: contactMHEjAiwa3hMeta?.name ?? "admin-admin-contact",
    path: contactMHEjAiwa3hMeta?.path ?? "/admin/admin/contact",
    meta: contactMHEjAiwa3hMeta || {},
    alias: contactMHEjAiwa3hMeta?.alias || [],
    redirect: contactMHEjAiwa3hMeta?.redirect,
    component: () => import("/myedvenn/pages/admin/admin/contact.vue").then(m => m.default || m)
  },
  {
    name: indexRSFixR0mo5Meta?.name ?? "admin-admin",
    path: indexRSFixR0mo5Meta?.path ?? "/admin/admin",
    meta: indexRSFixR0mo5Meta || {},
    alias: indexRSFixR0mo5Meta?.alias || [],
    redirect: indexRSFixR0mo5Meta?.redirect,
    component: () => import("/myedvenn/pages/admin/admin/index.vue").then(m => m.default || m)
  },
  {
    name: reportFHnuPIG1K6Meta?.name ?? "admin-admin-report",
    path: reportFHnuPIG1K6Meta?.path ?? "/admin/admin/report",
    meta: reportFHnuPIG1K6Meta || {},
    alias: reportFHnuPIG1K6Meta?.alias || [],
    redirect: reportFHnuPIG1K6Meta?.redirect,
    component: () => import("/myedvenn/pages/admin/admin/report.vue").then(m => m.default || m)
  },
  {
    name: attendance_45_91status_93nDPSdVpFEaMeta?.name ?? "admin-administrative-attendance-status",
    path: attendance_45_91status_93nDPSdVpFEaMeta?.path ?? "/admin/administrative/attendance-:status()",
    meta: attendance_45_91status_93nDPSdVpFEaMeta || {},
    alias: attendance_45_91status_93nDPSdVpFEaMeta?.alias || [],
    redirect: attendance_45_91status_93nDPSdVpFEaMeta?.redirect,
    component: () => import("/myedvenn/pages/admin/administrative/attendance-[status].vue").then(m => m.default || m)
  },
  {
    name: indexLPSL0LDxk9Meta?.name ?? "admin-administrative",
    path: indexLPSL0LDxk9Meta?.path ?? "/admin/administrative",
    meta: indexLPSL0LDxk9Meta || {},
    alias: indexLPSL0LDxk9Meta?.alias || [],
    redirect: indexLPSL0LDxk9Meta?.redirect,
    component: () => import("/myedvenn/pages/admin/administrative/index.vue").then(m => m.default || m)
  },
  {
    name: index73KrNXB8z5Meta?.name ?? "admin",
    path: index73KrNXB8z5Meta?.path ?? "/admin",
    meta: index73KrNXB8z5Meta || {},
    alias: index73KrNXB8z5Meta?.alias || [],
    redirect: index73KrNXB8z5Meta?.redirect,
    component: () => import("/myedvenn/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: datarfIHIoPQXmMeta?.name ?? "admin-market-data",
    path: datarfIHIoPQXmMeta?.path ?? "/admin/market/data",
    meta: datarfIHIoPQXmMeta || {},
    alias: datarfIHIoPQXmMeta?.alias || [],
    redirect: datarfIHIoPQXmMeta?.redirect,
    component: () => import("/myedvenn/pages/admin/market/data.vue").then(m => m.default || m)
  },
  {
    name: indexzvgibSL00FMeta?.name ?? "admin-market",
    path: indexzvgibSL00FMeta?.path ?? "/admin/market",
    meta: indexzvgibSL00FMeta || {},
    alias: indexzvgibSL00FMeta?.alias || [],
    redirect: indexzvgibSL00FMeta?.redirect,
    component: () => import("/myedvenn/pages/admin/market/index.vue").then(m => m.default || m)
  },
  {
    name: leadpeIpgb2xB0Meta?.name ?? "admin-market-lead",
    path: leadpeIpgb2xB0Meta?.path ?? "/admin/market/lead",
    meta: leadpeIpgb2xB0Meta || {},
    alias: leadpeIpgb2xB0Meta?.alias || [],
    redirect: leadpeIpgb2xB0Meta?.redirect,
    component: () => import("/myedvenn/pages/admin/market/lead.vue").then(m => m.default || m)
  },
  {
    name: _91attendanceId_93DHXaBa68wDMeta?.name ?? "doc-attendance-attendanceId",
    path: _91attendanceId_93DHXaBa68wDMeta?.path ?? "/doc/attendance/:attendanceId()",
    meta: _91attendanceId_93DHXaBa68wDMeta || {},
    alias: _91attendanceId_93DHXaBa68wDMeta?.alias || [],
    redirect: _91attendanceId_93DHXaBa68wDMeta?.redirect,
    component: () => import("/myedvenn/pages/doc/attendance/[attendanceId].vue").then(m => m.default || m)
  },
  {
    name: indexTFZplVLTX2Meta?.name ?? "index",
    path: indexTFZplVLTX2Meta?.path ?? "/",
    meta: indexTFZplVLTX2Meta || {},
    alias: indexTFZplVLTX2Meta?.alias || [],
    redirect: indexTFZplVLTX2Meta?.redirect,
    component: () => import("/myedvenn/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login5dXPFO301tMeta?.name ?? "login",
    path: login5dXPFO301tMeta?.path ?? "/login",
    meta: login5dXPFO301tMeta || {},
    alias: login5dXPFO301tMeta?.alias || [],
    redirect: login5dXPFO301tMeta?.redirect,
    component: () => import("/myedvenn/pages/login.vue").then(m => m.default || m)
  },
  {
    name: password_resetBM8o8ZmaLxMeta?.name ?? "password_reset",
    path: password_resetBM8o8ZmaLxMeta?.path ?? "/password_reset",
    meta: password_resetBM8o8ZmaLxMeta || {},
    alias: password_resetBM8o8ZmaLxMeta?.alias || [],
    redirect: password_resetBM8o8ZmaLxMeta?.redirect,
    component: () => import("/myedvenn/pages/password_reset.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mhhjjPFOSuMeta?.name ?? undefined,
    path: _91id_93mhhjjPFOSuMeta?.path ?? "/training/:id()",
    meta: _91id_93mhhjjPFOSuMeta || {},
    alias: _91id_93mhhjjPFOSuMeta?.alias || [],
    redirect: _91id_93mhhjjPFOSuMeta?.redirect,
    component: () => import("/myedvenn/pages/training/[id].vue").then(m => m.default || m),
    children: [
  {
    name: attendance7Ius3w9yMjMeta?.name ?? "training-id-attendance",
    path: attendance7Ius3w9yMjMeta?.path ?? "attendance",
    meta: attendance7Ius3w9yMjMeta || {},
    alias: attendance7Ius3w9yMjMeta?.alias || [],
    redirect: attendance7Ius3w9yMjMeta?.redirect,
    component: () => import("/myedvenn/pages/training/[id]/attendance.vue").then(m => m.default || m)
  },
  {
    name: calendarcLOju4kISdMeta?.name ?? "training-id-calendar",
    path: calendarcLOju4kISdMeta?.path ?? "calendar",
    meta: calendarcLOju4kISdMeta || {},
    alias: calendarcLOju4kISdMeta?.alias || [],
    redirect: calendarcLOju4kISdMeta?.redirect,
    component: () => import("/myedvenn/pages/training/[id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: contractac12tpG7WRMeta?.name ?? "training-id-contract",
    path: contractac12tpG7WRMeta?.path ?? "contract",
    meta: contractac12tpG7WRMeta || {},
    alias: contractac12tpG7WRMeta?.alias || [],
    redirect: contractac12tpG7WRMeta?.redirect,
    component: () => import("/myedvenn/pages/training/[id]/contract.vue").then(m => m.default || m)
  },
  {
    name: indexQSMAK5gz06Meta?.name ?? "training-id",
    path: indexQSMAK5gz06Meta?.path ?? "",
    meta: indexQSMAK5gz06Meta || {},
    alias: indexQSMAK5gz06Meta?.alias || [],
    redirect: indexQSMAK5gz06Meta?.redirect,
    component: () => import("/myedvenn/pages/training/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: welcome4xGeziDxZzMeta?.name ?? "welcome",
    path: welcome4xGeziDxZzMeta?.path ?? "/welcome",
    meta: welcome4xGeziDxZzMeta || {},
    alias: welcome4xGeziDxZzMeta?.alias || [],
    redirect: welcome4xGeziDxZzMeta?.redirect,
    component: () => import("/myedvenn/pages/welcome.vue").then(m => m.default || m)
  }
]