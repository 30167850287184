<template>
  <div :class="[($props.position.indexOf('t') === -1) ? 'justify-end' : 'justify-start', 'fixed z-50 inset-0 flex items-start flex-col p-6 px-4 py-6 pointer-events-none']">
    <div :class="[($props.position.indexOf('r') === -1) ? '' : 'ml-auto', 'w-full max-w-sm']">
      <TransitionGroup name="toast" move-class="toast-move">
        <div class="pointer-events-auto	 flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md cursor-pointer" v-for="notification in sortedNotifications" :key="notification.id" @click="remove(notification.id)" @mouseenter="mouseenter(notification)" @mouseleave="mouseleave(notification)">
          <div :class="['flex items-center justify-center w-12', level(notification.level).bg]">
            <CheckCircleIcon class="w-6 h-6 text-white" />
          </div>
          <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
              <span :class="['font-semibold', level(notification.level).text]">{{ notification.title }}</span>
              <p class="text-sm text-gray-600">{{ notification.text }}</p>
              <a class="text-sm text-gray-600 underline" v-if="notification.link" :href="notification.link" target="_blank">{{ notification.linkText }}</a>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { rootEmitter } from '@/plugins/emitter'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import { Notif, NotifLevel } from '@/types'

type Toast = Notif & { id?: number; timeoutId: number; timeout: number }

function defaulTimeout(lvl: NotifLevel) {
  switch (lvl) {
    case NotifLevel.error:
      return 10000;
    default:
      return 3000;
  }
}


export default defineComponent({
  components: {
    CheckCircleIcon
  },
  props: {
    group: {
      type: String,
      default: () => '',
    },
    position: {
      type: String,
      default: () => 'tr',
      validator: (value: string) => ['tr', 'tl', 'br', 'bl'].includes(value)
    },
    maxNotifications: {
      type: Number,
      default: () => 10,
    }
  },
  setup(props) {
    const notifications = ref<Toast[]>([])
    let notifId = 0

    const notificationsByGroup = computed(() => notifications.value.filter((n) => n.group === props.group))

    const sortedNotifications = computed(() => (props.position.indexOf('b') === -1) ?
      [...notificationsByGroup.value]
        .slice(0, props.maxNotifications)
      :
      [...notificationsByGroup.value]
        .reverse()
        .slice(0, props.maxNotifications)
    )

    const remove = (id: number) => {
      notifications.value.splice(notifications.value.findIndex(n => n.id === id), 1)
    }

    const add = ({ notification, timeout }: { notification: Notif, timeout: number }) => {
      if (notification.group !== props.group) return;

      (notification as Toast).id = notifId
      notifId += 1

      const timeoutId = setTimeout(remove, timeout || defaulTimeout(notification.level), (notification as Toast).id) as unknown as number
      notifications.value.push({ ...notification, timeout, timeoutId })
    }

    onMounted(() => rootEmitter.on('notify', add))

    return {
      notifications,
      notificationsByGroup,
      sortedNotifications,

      remove,

      mouseenter: (notif: Toast) => {
        clearTimeout(notif.timeoutId)
      },
      mouseleave: (notif: Toast) => {
        notif.timeoutId = setTimeout(remove, notif.timeout || defaulTimeout(notif.level), notif.id) as unknown as number
      },

      level(level: NotifLevel) {
        switch (level) {
          case NotifLevel.error:
            return { bg: 'bg-red-500', text: 'text-red-500' }
          case NotifLevel.warn:
            return { bg: 'bg-orange-300', text: 'text-orange-300' }
          case NotifLevel.info:
          default:
            return { bg: 'bg-green-500', text: 'text-green-500' }
        }
      }
    }
  }
})
</script>


<style lang="postcss">
.toast-enter-active {
  @apply transform ease-out duration-300 transition;
}
.toast-leave-active {
  @apply transition ease-in duration-500;
}
.toast-enter-from {
  @apply translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4;
}
.toast-enter-to {
  @apply translate-y-0 opacity-100 sm:translate-x-0;
}
.toast-leave-to {
  @apply opacity-0;
}
.toast-leave-from {
  @apply opacity-100;
}
.toast-move {
  @apply transition duration-500;
}
</style>