import { defineStore } from "pinia";

const screens = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    // '2xl': 1536
}

const sm = val => val < screens.md
const mdAndUp = val => val >= screens.md
const mdAndDown = val => val < screens.lg
const md = val => mdAndUp(val) && mdAndDown(val)
const lgAndUp = val => val >= screens.lg
const lgAndDown = val => val < screens.xl
const lg = val => lgAndUp(val) && lgAndDown(val)
const xl = val => val >= screens.xl

const getBreakpoint = w => ({
    sm: sm(w),
    md: md(w),
    mdAndUp: mdAndUp(w),
    mdAndDown: mdAndDown(w),
    lg: lg(w),
    lgAndUp: lgAndUp(w),
    lgAndDown: lgAndDown(w),
    xl: xl(w),
})

export const useStoreDisplay = defineStore('display', {

    state: () => {
        return  {
            breakpoint: getBreakpoint((process.client && window.innerWidth) ? window.innerWidth : screens.lg),
        }
    },

    actions: {
        resize(windowInnerWidth) {
            this.breakpoint = getBreakpoint(windowInnerWidth)
        }
    }
})