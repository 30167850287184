import revive_payload_client_4sVQNw7RlN from "/myedvenn/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/myedvenn/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/myedvenn/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/myedvenn/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/myedvenn/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/myedvenn/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/myedvenn/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/myedvenn/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/myedvenn/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_logger_uvaXDPpqs9 from "/myedvenn/plugins/01.logger.ts";
import api_GFfDXud5Cr from "/myedvenn/plugins/api.ts";
import axios_QMFgzss1s4 from "/myedvenn/plugins/axios.ts";
import emitter_eaMgM0oZTb from "/myedvenn/plugins/emitter.ts";
import filter_MBkhYQrnZm from "/myedvenn/plugins/filter.ts";
import store_9xNuDHGAVU from "/myedvenn/plugins/store.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _01_logger_uvaXDPpqs9,
  api_GFfDXud5Cr,
  axios_QMFgzss1s4,
  emitter_eaMgM0oZTb,
  filter_MBkhYQrnZm,
  store_9xNuDHGAVU
]